import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// UI Kit
import Button from 'ui-kit/button/button';
import ToastBox from 'ui-kit/toast-box/toast-box';

// Display components
import PlanInviteCardList from 'display-components/membership/plan-invite-card-list';

// Components
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

// State
import { accountFetchProfileRoutine } from 'state/account/account.routines';
import { accountProfileMembershipSelector } from 'state/account/account.selectors';
import { closeModal, openModal, setBusyModal } from 'state/birdi-modal/birdi-modal.reducers';
import { joinMembershipInvitationPlanStatusMap } from 'state/membership/membership.helpers';
import {
    membershipAcceptOrDeclineInvitationsRoutine,
    membershipDetailsRoutine,
    membershipGetInvitationsRoutine
} from 'state/membership/membership.routines';
import { membershipInvitationsSelector } from 'state/membership/membership.selector';

// Types
import { JoinMembershipPlanInvitationData } from 'types/join-membership-plan';
import { MembershipInvitationStatus, PlanInviteCardProps } from 'types/membership';

// Utils
import { lowercaseAndCapitalize } from 'util/cart';
import { addZero } from 'util/number';
import { capitalizeFirstLetter } from 'util/string';

// Styles
import './membership-invite-section.styles.scss';
import { getPhoneNumber } from 'util/globalVariables';

const getExpirationDays = (originalDate: string) => {
    const parsedDate = new Date(originalDate);
    const expirationDate = parsedDate.setDate(parsedDate.getDate() + 7);
    const expirationDateFormatted = new Date(expirationDate);
    const now = new Date();
    const total = expirationDateFormatted.getTime() - now.getTime();
    return Math.round(total / (1000 * 3600 * 24));
};

export const getMembershipInvitationStatus = (accountStatus: number): MembershipInvitationStatus => {
    const accountStatusLabel = joinMembershipInvitationPlanStatusMap.get(accountStatus);

    switch (accountStatusLabel) {
        case 'pending':
            return 'pending';
        case 'active':
            return 'accepted';
        case 'partialAccess':
            return 'accepted';
        default:
            return accountStatusLabel as MembershipInvitationStatus;
    }
};

const MembershipInviteSection: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isBusy, setIsBusy] = useState(false);
    const inviteList = useSelector(membershipInvitationsSelector);
    const profileMembership = useSelector(accountProfileMembershipSelector);
    const [inviteData, setInviteData] = useState<JoinMembershipPlanInvitationData | null>(null);

    useEffect(() => {
        if (inviteList && inviteList.invites.length > 0) {
            setInviteData(inviteList.invites[inviteList.invites.length - 1]);
        }
    }, [inviteList]);

    const showGenericErrorModal = useCallback(() => {
        dispatch(
            openModal({
                showClose: false,
                type: 'danger',
                size: 'lg',
                className: 'modal-membership-add-member-error',
                headerContent: (
                    <BirdiModalHeaderDanger
                        headerText={t('pages.profile.addFamilyMember.genericErrorModalTitle')}
                        icon="alert"
                    />
                ),
                onClose: () => {
                    setIsBusy(false);
                },
                bodyContent: (
                    <BirdiModalContentAlt
                        subTitle={t(`pages.medicineCabinet.messages.callbacks.autoRefillErrorMessage`)}
                        note={t(`pages.medicineCabinet.messages.callbacks.errorMessageNote`, {
                            phoneNumber: getPhoneNumber({ isEnd: true })
                        })}
                    />
                ),
                ctas: [
                    {
                        label: t(`pages.medicineCabinet.messages.labels.gotIt`),
                        variant: 'primary',
                        onClick: () => {
                            setIsBusy(false);
                            dispatch(closeModal({}));
                        }
                    }
                ]
            })
        );
    }, [dispatch, t]);

    const handleCloseModal = useCallback(
        (isReloadDetails: boolean = false) => {
            if (isReloadDetails) dispatch(membershipDetailsRoutine.trigger(profileMembership.membershipID));
            dispatch(closeModal({}));
        },
        [dispatch, profileMembership]
    );

    const handleAccept = useCallback(
        (values: Partial<PlanInviteCardProps>) => {
            setIsBusy(true);
            dispatch(
                membershipAcceptOrDeclineInvitationsRoutine.trigger({
                    inviteId: values.membershipInvitationId,
                    status: 0,
                    onSuccess: () => {
                        dispatch(accountFetchProfileRoutine.trigger());
                        dispatch(membershipGetInvitationsRoutine.trigger());
                        setIsBusy(false);
                        dispatch(
                            openModal({
                                showClose: true,
                                size: 'lg',
                                className: 'modal-membership-add-member',
                                onClose: () => handleCloseModal(true),
                                bodyContent: (
                                    <BirdiModalContent
                                        icon={'default'}
                                        title={t('pages.profile.membership.inviteSection.modalAccept.heading')}
                                        body={
                                            <div className="membership-invite-section-success-modal">
                                                <div>
                                                    <p>
                                                        {t(
                                                            'pages.profile.membership.inviteSection.modalAccept.message',
                                                            {
                                                                requestedByName: lowercaseAndCapitalize(
                                                                    values?.requestedByName || ''
                                                                )
                                                            }
                                                        )}
                                                    </p>
                                                </div>
                                                <Button
                                                    label={t(
                                                        'pages.profile.membership.inviteSection.modalAccept.ctaContinue'
                                                    )}
                                                    type="button"
                                                    variant="primary"
                                                    onClick={() => {
                                                        dispatch(closeModal({}));
                                                    }}
                                                />
                                            </div>
                                        }
                                    />
                                )
                            })
                        );
                    },
                    onFailure: () => {
                        showGenericErrorModal();
                    }
                })
            );
        },
        [dispatch, t, showGenericErrorModal, handleCloseModal]
    );

    const handleDecline = useCallback(
        (values: Partial<PlanInviteCardProps>) => {
            dispatch(
                openModal({
                    showClose: false,
                    onClose: () => handleCloseModal(true),
                    size: 'lg',
                    type: 'danger',
                    contentClassName: 'modal-content-padding',
                    headerContent: (
                        <BirdiModalHeaderDanger
                            headerText={t('pages.profile.membership.inviteSection.modalDecline.heading')}
                            icon="alert"
                        />
                    ),
                    bodyContent: (
                        <BirdiModalContentAlt
                            subTitle={t('pages.profile.membership.inviteSection.modalDecline.message', {
                                requestedByName: capitalizeFirstLetter(values?.requestedByName || '')
                            })}
                        />
                    ),
                    ctas: [
                        {
                            label: t('pages.profile.membership.inviteSection.modalDecline.ctaCancel'),
                            variant: 'link',
                            onClick: () => {
                                dispatch(closeModal({}));
                            }
                        },
                        {
                            label: t('pages.profile.membership.inviteSection.modalDecline.ctaContinue'),
                            variant: 'primary',
                            async: true,
                            onClick: () => {
                                setIsBusy(true);
                                dispatch(setBusyModal(true));
                                dispatch(
                                    membershipAcceptOrDeclineInvitationsRoutine.trigger({
                                        inviteId: values.membershipInvitationId,
                                        status: 1,
                                        onSuccess: () => {
                                            dispatch(membershipDetailsRoutine.trigger(profileMembership.membershipID));
                                            dispatch(accountFetchProfileRoutine.trigger());
                                            dispatch(membershipGetInvitationsRoutine.trigger());
                                            setIsBusy(false);
                                            dispatch(closeModal({}));
                                        },
                                        onFailure: () => {
                                            showGenericErrorModal();
                                        }
                                    })
                                );
                            }
                        }
                    ]
                })
            );
        },
        [dispatch, t, showGenericErrorModal, handleCloseModal]
    );

    const parsedDate = new Date(inviteData?.requestedDate as string);

    const month = parsedDate.getMonth() + 1;
    const day = parsedDate.getDate();
    const year = parsedDate.getFullYear();

    const formattedDate = `${addZero(month)}/${addZero(day)}/${year}`;
    const expirationDays = getExpirationDays(inviteData?.requestedDate as string);
    const invitationStatus = getMembershipInvitationStatus(inviteData?.status as number);

    const invitePayload: PlanInviteCardProps[] = [
        {
            planName: inviteData?.senderPlanName as string,
            requestedByName: `${inviteData?.senderFirstName} ${inviteData?.senderLastName}`,
            requestedByDate: formattedDate,
            invitationStatus,
            isBusy,
            membershipInvitationId: inviteData?.invitationId,
            onAccept: () =>
                handleAccept({
                    requestedByName: `${inviteData?.senderFirstName} ${inviteData?.senderLastName}`,
                    membershipInvitationId: inviteData?.invitationId
                }),
            onDecline: () =>
                handleDecline({
                    requestedByName: `${inviteData?.senderFirstName} ${inviteData?.senderLastName}`,
                    membershipInvitationId: inviteData?.invitationId
                }),
            isMembership: true,
            expirationDays,
            invitationId: inviteData?.invitationId
        }
    ];

    return (
        <div className="membership-invite-section">
            <div className="membership-invite-listItems">
                <PlanInviteCardList payload={invitePayload} />
            </div>
            {invitationStatus !== 'expired' && (
                <ToastBox variant="info">{t('pages.profile.membership.inviteSection.legalDisclaimer')}</ToastBox>
            )}
        </div>
    );
};

export default MembershipInviteSection;
