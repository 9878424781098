import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';
// UI kit
import LoadingMessage from 'ui-kit/loading-message/loading-message';
import Spinner from 'ui-kit/spinner/spinner';

// Display components
import ManageGroupCardList from 'display-components/membership/manage-group-card-list';

import { accountProfileSelector } from 'state/account/account.selectors';
import { familyProfileGetDependentsRoutine } from 'state/family-profile/family-profile.routines';
import {
    familyProfileDependentsActiveSelector,
    familyProfileIsLoadingSelector
} from 'state/family-profile/family-profile.selectors';
// States
import { membershipDetailSelector, membershipIsLoadingAddMemberSelector } from 'state/membership/membership.selector';

import { MEMBERSHIP_ADD_MEMBER_SCHEMA } from 'schema/membership/add-member';

// State, interfaces and types
import { ManageGroupCardProps } from 'types/membership';

// styles
import './membership-manage-group-form.styles.scss';

interface MembershipManageGroupFormProps {
    onSubmit: (values: any) => void;
    onCancel: () => void;
    defaultValues: MembershipManageGroupFormSchema;
}

export interface MembershipManageGroupFormSchema {
    memberTypeOption: 'Adult' | 'Minor' | undefined;
    selectedMember: ManageGroupCardProps | undefined;
}

const MembershipManageGroupForm = ({ onSubmit, onCancel, defaultValues }: MembershipManageGroupFormProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dependents = useSelector(familyProfileDependentsActiveSelector);
    const isFamilyAccountLoading = useSelector(familyProfileIsLoadingSelector);
    const profileObject = useSelector(accountProfileSelector);
    const isCaregiver = profileObject?.isCaregiver;
    const isLoading = useSelector(membershipIsLoadingAddMemberSelector);
    const membershipDetails = useSelector(membershipDetailSelector);

    useEffect(() => {
        if (isCaregiver && !dependents) dispatch(familyProfileGetDependentsRoutine.trigger());
    }, [dependents, dispatch, isCaregiver]);

    return (
        <Formik onSubmit={onSubmit} initialValues={defaultValues} validationSchema={MEMBERSHIP_ADD_MEMBER_SCHEMA}>
            {({ values, handleSubmit, setFieldValue, isValid, setFieldTouched }) => {
                const handleSelectMember = (obj: any) => {
                    setFieldValue(
                        'selectedMember',
                        {
                            ...obj,
                            ePostPatientNum: obj.ePostPatientNum,
                            memberName: obj.familyMemberName,
                            memberAge: obj.familyMemberAge,
                            memberDob: obj.dob,
                            memberFirstName: obj.familyMemberFirstName,
                            memberLastName: obj.familyMemberLastName,
                            memberInsuranceId: obj.insuranceId
                        },
                        true
                    );
                    setFieldTouched('selectedMember', false, false);
                    setFieldValue('memberTypeOption', undefined, false);
                };

                const dependentsPayload: ManageGroupCardProps[] = dependents
                    .filter(
                        (f) =>
                            ['fullAccess', 'partialAccess'].includes(f.accountStatus) &&
                            membershipDetails?.members.every(
                                (member) => member.epostPatientNum !== f.ePostPatientNum
                            ) &&
                            (f.insuranceId === '' || !f.insuranceId || f.insuranceId.includes('BRD')) &&
                            Number(f?.familyMemberAge || 0) > 2 &&
                            f.planAlias !== 'BRD01'
                    )
                    .map((dependent: any) => {
                        return {
                            ...dependent,
                            memberName: dependent.familyMemberName,
                            showRadioButton: true,
                            inputRadioElement: (
                                <input
                                    type="radio"
                                    name="selectedMember"
                                    value={dependent.ePostPatientNum}
                                    onChange={() => handleSelectMember(dependent)}
                                    checked={values.selectedMember?.ePostPatientNum === dependent.ePostPatientNum}
                                />
                            )
                        };
                    });

                if (isCaregiver && isFamilyAccountLoading) {
                    return (
                        <div className="membership-manage-group-form-loading">
                            <Spinner isDotsSpinner={true} isVisible={true} fullOverlay={false} t={t} />
                            <p>{t(`pages.profile.membership.manageGroupSection.loadingText`)}</p>
                            <LoadingMessage
                                text={t(`pages.profile.membership.manageGroupSection.loadingMessage`)}
                                isVisible
                            />
                        </div>
                    );
                }

                const labelClassName = classNames({
                    'membership-manage-group-form-error': !isValid
                });

                return (
                    <Form id="manage-group-form" onSubmit={handleSubmit} autoComplete="off">
                        <div className="membership-manage-group-form">
                            {isCaregiver && dependentsPayload.length > 0 && (
                                <div
                                    className={`membership-manage-group-form-items ${
                                        dependentsPayload?.length > 3 && 'membership-manage-group-form-items-overflow'
                                    }`}
                                >
                                    <p className={labelClassName}>
                                        {t('pages.profile.membership.manageGroupSection.modalAddMember.membersLabel')}
                                    </p>
                                    <ManageGroupCardList payload={dependentsPayload} hideContactDetails={true} />
                                </div>
                            )}

                            <div className="membership-manage-group-form-type">
                                <p className={labelClassName}>
                                    {t('pages.profile.membership.manageGroupSection.modalAddMember.typeLabel')}
                                </p>

                                <div className="radio-items">
                                    <label
                                        className={`radio-toggle--label border ${
                                            values.memberTypeOption === 'Adult' ? 'highlight' : ''
                                        } `}
                                        htmlFor="memberTypeOption"
                                    >
                                        <Field
                                            type="radio"
                                            name="memberTypeOption"
                                            value="Adult"
                                            className="radio-toggle--input"
                                            onChange={() => {
                                                setFieldValue('memberTypeOption', 'Adult', true);
                                                setFieldTouched('memberTypeOption', false, false);
                                                setFieldValue('selectedMember', undefined, false);
                                            }}
                                        />
                                        Adult
                                    </label>
                                    <label
                                        className={`radio-toggle--label border ${
                                            values.memberTypeOption === 'Minor' ? 'highlight' : ''
                                        }`}
                                        htmlFor="memberTypeOption"
                                    >
                                        <Field
                                            type="radio"
                                            name="memberTypeOption"
                                            value="Minor"
                                            className="radio-toggle--input"
                                            onChange={() => {
                                                setFieldValue('memberTypeOption', 'Minor', true);
                                                setFieldTouched('memberTypeOption', false, false);
                                                setFieldValue('selectedMember', undefined, false);
                                            }}
                                        />
                                        Minor
                                    </label>
                                </div>
                            </div>
                            <div className="membership-manage-group-form-action">
                                <Button
                                    async
                                    label={t('pages.profile.membership.manageGroupSection.modalAddMember.ctaContinue')}
                                    type="submit"
                                    variant="primary"
                                    isBusy={isLoading}
                                    disabled={isLoading}
                                />
                                <Button
                                    label={t('pages.profile.membership.manageGroupSection.modalAddMember.ctaCancel')}
                                    className="md-full md-pad-y-2"
                                    type="button"
                                    variant="link"
                                    onClick={onCancel}
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default MembershipManageGroupForm;
